<template>
  <el-container>
    <el-row style="width: 100%">
      <el-col :span="24">
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          label-width="200px"
          label-position="right"
          size="mini"
        >
          <el-form-item label="名称" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="描述" prop="content">
            <el-input type="textarea" rows="5" v-model="form.content"></el-input>
          </el-form-item>
          <el-form-item label="关联区域">
            <el-card class="box-card" style="width:100%" shadow="never">
              <el-button class="cardTitleBtn" style="margin-left:10px;" @click="addOptionByGroup">按组添加</el-button>
              <el-button class="cardTitleBtn" @click="addOption">普通添加</el-button>
              <el-table :data="form.communityList" style="width: 100%">
                <el-table-column prop="provinceName" label="省"></el-table-column>
                <el-table-column prop="cityName" label="市"></el-table-column>
                <el-table-column prop="districtName" label="区"></el-table-column>
                <el-table-column prop="streetName" label="街道"></el-table-column>
                <el-table-column prop="committeeName" label="居委"></el-table-column>
                <el-table-column prop="name" label="小区"></el-table-column>
                <el-table-column prop="name" label="操作">
                  <template slot-scope="scope">
                    <el-button type="danger" size="mini" @click="delRow(scope.row.guid)">移除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-card>
          </el-form-item>
          <el-form-item label="关联问卷">
            <el-card class="box-card" style="width:100%" shadow="never">
              <div>
                <el-transfer
                  @change="patrolSurveyChange"
                  :titles="['未关联', '已关联']"
                  :button-texts="['取消关联', '添加关联']"
                  filterable
                  :filter-method="filterMethod"
                  filter-placeholder="请输入"
                  v-model="form.patrolSurveyGuidList"
                  :data="patrolSurveyList">
                </el-transfer>
              </div>
            </el-card>
          </el-form-item>
          <el-form-item label="巡检时间段" v-if="!!form.patrolSurveyList.length">
            <el-card class="box-card" style="width:100%" shadow="never">
              <div v-for="patrolSurvey in form.patrolSurveyList" :key="patrolSurvey.guid">
                <el-button type="success" class="cardTitleBtn" @click="customExport('community',patrolSurvey.patrolSurveyGuid)" style="margin-right:5px">小区检查进展明细导出</el-button>
                <!-- <el-button type="success" class="cardTitleBtn" @click="customExport('detailed',patrolSurvey.patrolSurveyGuid)" style="margin-right:5px">得分明细导出</el-button> -->
                <el-button type="success" class="cardTitleBtn" @click="scoreDetails(patrolSurvey.patrolSurveyGuid)" style="margin-right:5px">得分明细导出</el-button>
                <el-button type="success" class="cardTitleBtn" @click="customExport('score',patrolSurvey.patrolSurveyGuid)" style="margin-right:5px">得分排名情况导出</el-button>
                <el-button type="success" class="cardTitleBtn" @click="customExport('procurator',patrolSurvey.patrolSurveyGuid)" style="margin-right:5px">
                检查员导出</el-button>
                <el-button type="success" class="cardTitleBtn" @click="customExport('street',patrolSurvey.patrolSurveyGuid)" style="margin-right:5px">街镇进展导出</el-button>
                <p>{{patrolSurvey.name}}</p>
                <div v-for="patrolTime in patrolSurvey.patrolTimesList" :key="patrolTime.patrolTimes">
                  <el-divider content-position="left">第 {{patrolTime.patrolTimes}} 次巡检</el-divider>
                  <el-date-picker
                    prop="dateRange"
                    value-format="yyyy-MM-dd"
                    @input="(value) => dateRangeChange(value, patrolTime, patrolSurvey)"
                    v-model="patrolTime.dateRange"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                    >
                  </el-date-picker>
                </div>
                <el-divider></el-divider>
              </div>
            </el-card>
          </el-form-item>
          <el-form-item>
            <el-button type="danger" @click="onDelete" v-if="form.guid !== ''"
              >删除</el-button>
            <el-button type="primary" @click="onSubmit('保存')">保存</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-dialog title="选择小区" :visible.sync="communityDialogVisible" width="50%">
      <el-cascader
      style="width:100%"
      clearable
      collapse-tags
      v-model="treeValue"
      :options="allTree"
      :props='{value:"value",label:"label",multiple: true }'
     ></el-cascader>
      <span slot="footer" class="dialog-footer">
        <el-button @click="communityDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doAddOption()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="选择分组" :visible.sync="communityGroupDialogVisible" width="50%">
      <el-select v-model="communityGroupGuid" @change="changeCommunityGroup" filterable placeholder="请选择">
        <el-option
          v-for="item in communityGroups"
          :key="item.guid"
          :label="item.name"
          :value="item.guid">
        </el-option>
      </el-select>
      <div class="targetCommunityList">
        <div class="el-dialog__title">分组小区</div>
        <el-table :data="targetCommunityList" style="width: 100%">
          <el-table-column prop="provinceName" label="省"></el-table-column>
          <el-table-column prop="cityName" label="市"></el-table-column>
          <el-table-column prop="districtName" label="区"></el-table-column>
          <el-table-column prop="streetName" label="街道"></el-table-column>
          <el-table-column prop="committeeName" label="居委"></el-table-column>
          <el-table-column prop="name" label="小区"></el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="communityGroupDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doAddOptionByGroup()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="得分明细导出" :visible.sync="scoreDialogVisible" width="50%">
      <el-form label-width="100px">
        <el-form-item label="街道：">
          <el-select v-model="streetGuid" placeholder="请选择" clearable>
            <el-option v-for="item in scoreStreetList" :key="item.guid" :label="item.name" :value="item.guid">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="巡检阶段：">
          <el-select v-model="queryPatrolTime" placeholder="请选择" clearable>
            <el-option v-for="item in patrolTimes.patrolTimes" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="scoreDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="customExport('detailed')">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      rules: {
        name: [{required: true, message: "标题不能为空", trigger: "blur"}],
        content: [{required: true, message: "描述不能为空", trigger: "blur"}],
        startDate: [{required: true, message: "开始日期不能为空", trigger: "blur"}],
        endDate: [{required: true, message: "截止日期不能为空", trigger: "blur"}],
        dateRange: [{required: true, message: "开始、截止日期不能为空", trigger: "blur"}]
      },
      userInfo: {},
      form: {
        guid: "",
        name: "",
        content: "",
        communityGuidList: [],
        patrolSurveyGuidList: [],
        communityList: [],
        patrolSurveyList: [],
        dataRange: null,
      },
      communityDialogVisible: false,
      patrolDialogVisible: false,
      patrolSurveyList: [], // 全部巡检问卷列表
      communityGroups: [], // 小区分组列表
      communityGroup: {},
      communityGroupGuid: "",
      communityGroupDialogVisible: false,
      targetCommunityList:[],
      treeValue: ['','','','','',''],
      allTree: [],
      scoreDialogVisible: false,
      scoreStreetList: [],
      streetGuid: '',
      patrolTimes: '',
      queryPatrolTime: '',
      patrolSurveyGuid: ''
    };
  },
  created() {
    let self = this;
    self.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    self.getProjectDetail(self.$route.query.guid || null);
    self.getPatrolSurveyList();
    self.getCommunityGroupList();
    self.getAllTree()
  },
  mounted() {
  },
  methods: {
    getProjectDetail(guid) {
      let self = this;
      if (guid) {
        self.$axios
          .get("/project/get/" + guid, {
            headers: { token: self.userInfo.token },
          })
          .then(function (response) {
            self.form.guid = response.data.data.guid;
            self.form.name = response.data.data.name;
            self.form.content = response.data.data.content;
            self.form.createTime = response.data.data.createTime;
            self.form.communityList = response.data.data.communityList;
            self.form.patrolSurveyGuidList = self.$underscore.map(response.data.data.patrolSurveyList, function(_el) {
              return _el.guid;
            });
            self.form.patrolSurveyList = []
            self.$underscore.each(response.data.data.patrolSurveyList, function(_el) {
              let _temp = {
                guid: _el.guid,
                patrolSurveyGuid: _el.guid,
                name: _el.name,
                content: _el.content,
                patrolTimes: _el.patrolTimes,
                patrolTimesList: []
              };
              _temp.patrolTimesList = self.$underscore.map(_el.patrolTimesList, function(sub_el) {
                return {
                  dateRange: [sub_el.startDate, sub_el.endDate],
                  startDate: sub_el.startDate,
                  endDate: sub_el.endDate,
                  patrolTimes: sub_el.patrolTimes
                }
              })
              self.form.patrolSurveyList.push(_temp);
            })
            self.form.communityGuidList = self.$underscore.map(self.form.communityList, function(_el ) {
              return _el.guid
            });
            self.form.districtGuidList=[];
            self.form.streetGuidList=[];
          })
          .catch(function (error) {
            self.$message({
              message: error.response.data.message,
              type: "warning",
            });
          });
      }
    },
    getCommunityGroupList() {
      let self = this;
      self.$axios
        .get("/community-group/list2", {
          headers: { token: self.userInfo.token },
        }).then(function(response) {
          self.communityGroups = response.data.data;
        }).catch(function (error) {
          self.$message({
            message: error.response.data.message,
            type: "warning",
          });
        });
    },
    onDelete() {
      let self = this;
      self
        .$confirm("确定删除 " + (self.form.name || "未命名用户") + "？")
        .then(() => {
          self.$axios
            .delete("/project/delete", {
              data: { guid: self.form.guid },
              headers: { token: self.userInfo.token },
            })
            .then(() => {
              self.$message({
                message: "删除成功",
                type: "success",
              });
              self.$router.push({ name: "ProjectList" });
            })
            .catch((error) => {
              self.$message({
                message: error.response.data.message,
                type: "warning",
              });
            });
        })
        .catch(() => {});
    },
    onSubmit(type) {
      let self = this;
      self.$refs["form"].validate((valid) => {
        if (self.form.patrolSurveyList.length > 0) {
          console.log("self.form.patrolSurveyList.length > 0 = ", self.form.patrolSurveyList.length > 0);
          for (let i = 0; i < self.form.patrolSurveyList.length; i++) {
            for (let j = 0; j < self.form.patrolSurveyList[i].patrolTimesList.length; j++) {
              if (!((self.form.patrolSurveyList[i].patrolTimesList[j].startDate.length > 0) && (self.form.patrolSurveyList[i].patrolTimesList[j].endDate.length > 0))) {
                self.$message({
                  message: "巡检开始日期或截止日期不可为空",
                  type: "warning",
                });
                return false;
              }
            }
          }
        }
        if (valid) {
          let data = JSON.parse(JSON.stringify(self.form));
          self.$axios
            .post("/project/saveOrUpdate", data, {
              headers: {
                "content-type": "application/json",
                token: self.userInfo.token,
              },
            }).then(function (res) {
              self.$message({
                message: "操作成功",
                type: "success",
              });
              if(type=='保存'){
                self.$router.push({ name: "ProjectList" });
              } else if(type=='提交') {
                self.getProjectDetail(res.data.data.guid || null);
              }
            }).catch(function (error) {
              self.$message({
                message: error.response.data.message,
                type: "warning",
              });
            });
        } else {
          self.$message({
            message: "请输入正确的项目信息",
            type: "warning",
          });
          return false;
        }
      })
    },
    delRow(guid) {
      let self = this;
      self.form.communityList = self.form.communityList.filter(
         value=> value.guid != guid
      );
      self.form.communityGuidList = self.form.communityGuidList.filter(
         value  => value !== guid
      );
    },
    getAllTree() {
      let self = this
      self.$axios.get('/community/tree',{
        headers: { token: self.userInfo.token },
      }).then(function(res) {
        self.getTreeData(res.data.data)
        self.allTree = res.data.data
      }).catch(function (error) {
        self.$message({
          message: "列表获取失败：" + error.response.data.message,
          type: "warning",
        });
      })
    },
    getTreeData(data){
        for(var i=0;i<data.length;i++){
            if(data[i].children.length<1){
                data[i].children=undefined;
            }else {
                this.getTreeData(data[i].children);
            }
        }
        return data;
    },
    addOption() {
      let self = this;
      self.treeValue = ['','','','','','']
      self.communityDialogVisible = true;
    },
    doAddOption(){
      let self = this;
      self.treeValue.forEach(item => {
        if (item[5] == "") {
          self.$message({
            message: "区域必须精确到小区",
            type:"warning",
          });
          return ;
        }
        let params = {
          provinceGuid: item[0],
          cityGuid: item[1],
          districtGuid: item[2],
          streetGuid: item[3],
          committeeGuid: item[4],
          guid: item[5],
        };
        console.log("params = ", params);
        console.log("self.form = ", self.form);
        self.form.communityList.push(params);
        if(item[5]!=""){
          self.form.communityGuidList.push(item[5]);
        }
        else if(item[3]!=""){
          self.form.streetGuidList.push(item[3]);
        }else if(item[2]!=""){
          self.form.districtGuidList.push(item[2]);
        }
      })
      self.onSubmit('提交')
      self.communityDialogVisible = false;
    },
    addOptionByGroup() {
      let self = this;
      self.communityGroupDialogVisible = true;
    },
    doAddOptionByGroup() {
      let self = this;
      self.$axios
        .get("community-group/get/" + self.communityGroupGuid, {
          headers: { token: self.userInfo.token },
        }).then(function(response) {
          self.$underscore.each(response.data.data.communityList, function(_el) {
            self.form.communityGuidList.push(_el.guid);
            self.form.communityList.push(_el)
          })
          self.form.communityGuidList = self.$underscore.uniq(self.form.communityGuidList);
          self.form.communityList = self.$underscore.uniq(self.form.communityList, function(_el) {
            return _el.guid
          })
          self.communityGroupDialogVisible = false;
        }).catch(function (error) {
          self.$message({
            message: "小区列表获取失败：" + error.response.data.message,
            type: "warning",
          });
        });
    },
    // 问卷部分 START
    getPatrolSurveyList() {
      let self = this;
      self.$axios
        .get("/patrol-survey/list", {
          headers: { token: self.userInfo.token },
        }).then(function(response) {
          self.patrolSurveyList = [];
          for (let i = 0; i < response.data.data.length; i++) {
            let _temp = response.data.data[i];
            self.patrolSurveyList.push(Object.assign(_temp, {key: _temp.guid, label: _temp.name}));
          }
        }).catch(function (error) {
          self.$message({
            message: "巡检问卷列表获取失败：" + error.response.data.message,
            type: "warning",
          });
        });
    },
    addPatrolSurveyOption() {
      let self = this;
      self.patrolDialogVisible = true;
    },
    filterMethod(query, item) {
      return item.label.indexOf(query) > -1;
    },
    patrolSurveyChange(dataArray, direction) {
      let self = this;
      console.log("direction = ", direction);
      console.log("dataArray = ", dataArray);
      console.log("length = ", dataArray.length);
      console.log("self.form.patrolSurveyList = ", self.form.patrolSurveyList);
      // 取消关联
      if (direction == 'left') {
        let _tempPatrolSurveyList = [];
        self.$underscore.each(self.form.patrolSurveyList, function(_el) {
          let _tempPatrolSurvey = self.$underscore.find(dataArray, function(sub_el) {
            return sub_el == _el.guid;
          })
          console.log("_tempPatrolSurvey = ", _tempPatrolSurvey);
          if (_tempPatrolSurvey) {
            _tempPatrolSurveyList.push(_el)
          }
        })
        console.log("_tempPatrolSurveyList = ", _tempPatrolSurveyList);
        self.form.patrolSurveyList = [];
        self.$underscore.each(_tempPatrolSurveyList, function(_el) {
          self.form.patrolSurveyList.push(_el);
        })
        console.log("self.form.patrolSurveyList = ", self.form.patrolSurveyList);
        return ;
      }
      // 添加关联
      for (let i = 0; i < dataArray.length; i++) {
        let patrolSurveyExist = !!self.$underscore.find(self.form.patrolSurveyList, function(_el) {
          return _el.guid == dataArray[i];
        })
        if (patrolSurveyExist) {
          continue;
        }
        let _temp = self.$underscore.find(self.patrolSurveyList, function(_el) {
          return _el.guid == dataArray[i];
        });
        _temp.patrolSurveyGuid = _temp.guid;
        _temp.patrolTimesList = [];
        console.log("_temp = ", _temp);
        self.form.patrolSurveyList.push(_temp);
        for (let j = 0; j < _temp.patrolTimes; j++) {
          self.$set(self.form.patrolSurveyList[self.form.patrolSurveyList.length-1].patrolTimesList, j, {
            dateRange: null,
            startDate: "",
            endDate: "",
            patrolTimes: j + 1,
          })
        }
      }
    },
    dateRangeChange(value, patrolTime, patrolSurvey) {
      let self = this;
      console.log("value = ", value);
      console.log("patrolTime = ", patrolTime);
      console.log("patrolSurvey = ", patrolSurvey);
      let index = 0;
      for (let i = 0; i < self.form.patrolSurveyList.length; i++) {
        if (self.form.patrolSurveyList[i].guid == patrolSurvey.guid) {
          index = i;
          break;
        }
      }
      let patrolTimesIndex = 0;
      for (let i = 0; i < self.form.patrolSurveyList[index].patrolTimesList.length; i++) {
        if (self.form.patrolSurveyList[index].patrolTimesList[i].patrolTimes == patrolTime.patrolTimes) {
          patrolTimesIndex = i;
          break;
        }
      }
      self.$set(self.form.patrolSurveyList[index].patrolTimesList[patrolTimesIndex], "dateRange", [value[0], value[1]])
      self.$set(self.form.patrolSurveyList[index].patrolTimesList[patrolTimesIndex], "startDate", value[0])
      self.$set(self.form.patrolSurveyList[index].patrolTimesList[patrolTimesIndex], "endDate", value[1])
      self.$forceUpdate();
    },
    changeCommunityGroup(val){
      let self = this;
      let target = self.communityGroups.find(function(e){ return e.guid == val});
      self.targetCommunityList = target.communityList
    },
    // 问卷部分 END
    scoreDetails(patrolSurveyGuid) {
      let self = this
      self.patrolSurveyGuid = patrolSurveyGuid
      self.$axios.get('/export/street/list?projectGuid='+self.form.guid,{
        headers: { token: self.userInfo.token }
      })
      .then(function(res) {
        self.scoreStreetList = res.data.data
      })
      .catch(function(err) {
        self.$message({
          message: err.response.data.message,
          type: "warning",
        });
      })
      self.patrolTimes = self.form.patrolSurveyList.find(e=>{
        if(e.guid == patrolSurveyGuid) {
          return e.patrolTimes
        }
      })
      self.queryPatrolTime = ''
      self.streetGuid = ''
      self.scoreDialogVisible = true
    },
    customExport(type,id) {
      let self = this
      let url
      let projectGuid = self.form.guid
      if(type == 'community') {
        url = this.GLOBAL.host + "/export/community?projectGuid=" + projectGuid + "&patrolSurveyGuid=" + id
      } else if (type == 'score') {
        url = this.GLOBAL.host + "/export/score?projectGuid=" + projectGuid + "&patrolSurveyGuid=" + id
      } else if (type == 'procurator') {
        url = this.GLOBAL.host + "/export/staff?projectGuid=" + projectGuid + "&patrolSurveyGuid=" + id
      } else if (type == 'street') {
        url = this.GLOBAL.host + "/export/street?projectGuid=" + projectGuid + "&patrolSurveyGuid=" + id
      } else if (type == 'detailed') {
        if(self.queryPatrolTime) {
          url = this.GLOBAL.host + "/export/detail?projectGuid=" + projectGuid + "&patrolSurveyGuid=" + self.patrolSurveyGuid + "&streetGuid=" + self.streetGuid + "&queryPatrolTime=" + parseInt(self.queryPatrolTime)
        } else {
          url = this.GLOBAL.host + "/export/detail?projectGuid=" + projectGuid + "&patrolSurveyGuid=" + self.patrolSurveyGuid + "&streetGuid=" + self.streetGuid
        }
      }
      var a = document.createElement("a");
      a.href = url;
      a.target = "_blank";
      a.click();
    }
  }
}
</script>

<style>
.el-input.is-disabled .el-input__inner,
.el-textarea.is-disabled .el-textarea__inner {
  color: #606266 !important;
}
.approveBtn {
  width: 100%;
  margin-left: 0 !important;
  margin-right: 0 !important;
  /* margin-top: 10px; */
  margin-bottom: 10px;
}
.dec {
  color: #606266 !important;
  padding: 5px 0 10px 0;
}
.targetCommunityList{
  margin-top: 10px;
}
.targetCommunityList>div{
  padding: 10px 0;
}
.el-transfer-panel{
  width: 30%;
}
</style>
